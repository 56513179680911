// adds three cards as a section
// can be modified later to add things other than cards
import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontSizes,
  lineHeights,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading2, Heading3 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import PageWrapper from '../PageWrapper/PageWrapper';
import sanityImage from '../../../utils/sanityImage';
import Link from '../../atoms/Link/Link';
import Card from '../../atoms/Card/Card';
import Image from '../../atoms/Image/Image';
import Section from '../../molecules/Section/Section';

const ThreeCardSection = ({ ctaCards, headingText, sectionStyles }) => {
  const cardContainerCss = css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${uc('20px')};

    @media (${breakpoints.desktop}) {
      margin: ${uc('20px')};
    }

    @media (${breakpoints.largeTablet}) {
      flex-direction: column;
      justify-content: space-around;
      margin-bottom: 0;
    }
  `;

  const cardCss = css`
    flex: 1 0 33%;
    max-width: ${uc('432px')};

    a {
      display: flex;
      padding: ${uc('40px 20px')};
      text-decoration: none;

      > div {
        text-align: left;
      }
    }

    .img-wrapper {
      flex-shrink: 0;
      align-self: center;
      width: ${uc('100px')};
      height: ${uc('100px')};
      margin-right: ${uc('20px')};
    }

    img {
      flex-shrink: 0;
      align-self: center;
      margin-right: ${uc('20px')};
      border-radius: 50%;
    }

    @media (${breakpoints.desktop}) {
      flex: 1 0 30%;
      max-width: 30%;
    }

    @media (${breakpoints.largeTablet}) {
      width: 100%;
      max-width: calc(100% - ${uc('40px')});
      margin-bottom: ${uc('20px')};
    }

    @media (${breakpoints.mobile}) {
      a {
        flex-direction: column;

        > div {
          margin-top: ${uc('20px')};
          text-align: center;

          h4 {
            margin-bottom: 0;
          }
        }
      }
    }
  `;

  const textCss = css`
    margin-bottom: ${uc('15px')};
    color: ${colors.darkGray.two};
    font-size: ${fontSizes.sixteen};
    line-height: ${lineHeights.copy};
  `;

  const sectionCss = css`
    position: relative;
    text-align: center;
  `;

  const subheadingCss = css`
    margin-bottom: ${uc('15px')};
    color: ${colors.darkGray.two};
    font-size: ${fontSizes.sixteen};
    line-height: ${lineHeights.copy};
    text-transform: uppercase;
  `;

  const cardImageCss = css`
    width: ${uc('100px')};
    height: ${uc('100px')};
  `;

  /**
   * Get the color to use from base
   *
   * @param color
   * @return {*|boolean}
   */
  const getColor = color => {
    let colorValue = false;
    switch (color) {
      case 'lightGray':
        colorValue = colors.lightGray.one;
        break;
      case 'gray':
        colorValue = colors.darkGray.two;
        break;
      default:
        colorValue = colors[color];
    }

    return colorValue;
  };

  /**
   * Render each card
   */
  const renderedCards = ctaCards.map(card => {
    let headingColor = false;
    if (card.headingColor === undefined) {
      headingColor = getColor('gray');
    } else {
      headingColor = getColor(card.headingColor);
    }

    const headingCss = css`
      margin-bottom: ${uc('15px')};
      color: ${headingColor};
      font-size: ${fontSizes.twenty};
    `;

    return (
      <Card
        css={cardCss}
        backgroundColor={colors.white}
        boxShadow={shadows.large}
        hoverStyles={{ boxShadow: shadows.largeHovered }}
        key={`card-component-${card._key}`}
      >
        <Link to={card.link}>
          <Image
            css={cardImageCss}
            src={sanityImage(card.image)
              .auto('format')
              .width(90)
              .url()}
            alt={card.alt}
          />
          <div>
            {card.subheading && (
              <Text css={subheadingCss}>{card.subheading}</Text>
            )}
            {card.heading && (
              <Heading3 css={headingCss}>{card.heading}</Heading3>
            )}
            {card.text && <Text css={textCss}>{card.text}</Text>}
          </div>
        </Link>
      </Card>
    );
  });

  return (
    <Section sectionStyles={sectionStyles} css={sectionCss}>
      <PageWrapper>
        {headingText && <Heading2>{headingText}</Heading2>}
        <div css={cardContainerCss}>{renderedCards}</div>
      </PageWrapper>
    </Section>
  );
};

ThreeCardSection.propTypes = {
  ctaCards: PropTypes.arrayOf(PropTypes.shape({})),
  headingText: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

ThreeCardSection.defaultProps = {
  ctaCards: {},
  headingText: '',
  sectionStyles: {},
};

export default ThreeCardSection;
